import {
  CargoManifestType,
  DockingChecklist,
  GenericQueryParamsType,
  GenericResponse,
  Stopover,
  StopoverGroupByOperatorType,
  StopoverGroupbyDockingStatisticsType,
  StopoverGroupbyStorageMerchantType,
  StopoversGroupByOperatorType,
} from '../types';
import { formatFinancialTablesToForm } from '../utils/formatters';
import { maskString } from '../utils/utils';
import { groupCEMerchantByOperatorCompany } from './formatters';
import { getHeaders, tosApi } from './tosApi';
import { getInvalidatesTags } from './utils';

export type StopoverResponse = GenericResponse & {
  results: Array<Stopover> | [];
};

export type StopoversGroupByOperatorResponse = GenericResponse & {
  results: Array<StopoversGroupByOperatorType> | [];
};

export type StopoverGroupbyDockingStatisticsResponse = GenericResponse & {
  results: StopoverGroupbyDockingStatisticsType[];
};

type StopoverGroupbyDockingStatisticsParamsType = {
  initial_date_report?: string | null;
  final_date_report?: string | null;
  code_or_vessel__name?: string;
};

type StopoverByOperationParamsType = GenericQueryParamsType & {
  duv_or_vessel_name_or_imo_or_docking_place_name?: string;
  report_filter?: string;
};

type StopoverByOperatorParamsType = GenericQueryParamsType & {
  detail_filter_operation_id?: number;
  detail_filter_operator?: string;
  detail_filter_product_group?: string;
};

export type ReportsQueryParamsType = {
  initial_date: string | null;
  final_date: string | null;
};

export type StopoverFiltersType = {
  vessel_imo?: string;
};
const BASE_URL = '/maritime/stopoveres';

export const stopoverApi = tosApi.injectEndpoints({
  endpoints: (build) => ({
    addStopover: build.mutation<Stopover, any>({
      query(stopover) {
        return {
          url: BASE_URL,
          method: 'POST',
          body: stopover,
        };
      },
      transformResponse: (response: Stopover) => {
        response.duv = maskString(response.duv || '', '######/####');
        return response;
      },
      invalidatesTags: (_result, error) =>
        getInvalidatesTags(error, [
          { type: 'Stopover', code: '1' },
          { type: 'Stopover', code: '3' },
        ]),
    }),
    getStopover: build.query<Stopover, number>({
      query: (id) => {
        return {
          url: `${BASE_URL}/${id}`,
          method: 'GET',
          headers: getHeaders,
        };
      },
      providesTags: (_result, _error, code) => [{ type: 'Stopover', code }],
    }),
    getStopoveres: build.query<StopoverResponse, StopoverFiltersType | void>({
      query: (filters) => {
        return {
          url: BASE_URL,
          method: 'GET',
          params: filters || {},
        };
      },
    }),
    updateStopover: build.mutation<
      Stopover,
      Partial<Stopover> & Pick<Stopover, 'id'>
    >({
      query: ({ id, ...stopover }) => {
        const toSave: any = { ...stopover };
        return {
          url: `${BASE_URL}/${id}`,
          method: 'PUT',
          body: toSave,
        };
      },
      transformResponse: (response: Stopover) => {
        response.duv = maskString(response.duv || '', '######/####');
        response.dockings = formatFinancialTablesToForm(
          response.dockings || []
        );
        return response;
      },
      invalidatesTags: (_result, error) =>
        getInvalidatesTags(error, [
          { type: 'Stopover', code: '1' },
          { type: 'Stopover', code: '3' },
        ]),
    }),
    getStopoverDockingChecklist: build.query<DockingChecklist, number | null>({
      query: (id) => {
        return {
          url: `${BASE_URL}/${id}/check-docking`,
          method: 'GET',
          headers: getHeaders,
        };
      },
      providesTags: () => [{ type: 'Stopover', code: '2' }],
    }),
    getOperators: build.query<{ results: Array<any> }, void>({
      query: () => {
        return {
          url: `/maritime/operators`,
          method: 'GET',
          headers: getHeaders,
        };
      },
    }),
    cancel: build.mutation<Stopover, { id: number; motivation?: string }>({
      query: (data) => {
        const { id, motivation } = data;
        return {
          url: `${BASE_URL}/${id}/cancel`,
          method: 'PATCH',
          body: { motivation },
        };
      },
      invalidatesTags: (_result, error) =>
        getInvalidatesTags(error, [
          { type: 'StopoverLog', code: '1' },
          { type: 'Stopover', code: '1' },
          { type: 'Stopover', code: '3' },
          { type: 'Docking', code: 'docking-window' },
        ]),
    }),
    reactivate: build.mutation<Stopover, { id: number; motivation?: string }>({
      query: (data) => {
        const { id, motivation } = data;
        return {
          url: `${BASE_URL}/${id}/reactivate`,
          method: 'PATCH',
          body: { motivation },
        };
      },
      invalidatesTags: (_result, error) =>
        getInvalidatesTags(error, [
          { type: 'StopoverLog', code: '1' },
          { type: 'Stopover', code: '1' },
          { type: 'Stopover', code: '3' },
          { type: 'Docking', code: 'docking-window' },
        ]),
    }),
    deleteProduct: build.mutation<any, number>({
      query: (id) => {
        return {
          url: `/maritime/product/${id}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: (_result, error) =>
        getInvalidatesTags(error, [{ type: 'Stopover', code: '3' }]),
    }),
    filterStopoveresByNameOrCode: build.query<any, string>({
      query: (imo_or_code) => {
        return {
          url: BASE_URL,
          method: 'GET',
          headers: getHeaders,
          params: { imo_or_code },
        };
      },
    }),
    patchStopover: build.mutation<Stopover, Partial<Stopover>>({
      query: (stopover) => {
        const stopoverToSave = { ...stopover };
        delete stopoverToSave.id;
        return {
          url: `${BASE_URL}/${stopover.id}`,
          method: 'PATCH',
          body: stopoverToSave,
        };
      },
      invalidatesTags: (_result, error) =>
        getInvalidatesTags(error, [{ type: 'Stopover', code: '1' }]),
    }),
    getStopoversGroupbyOperation: build.query<
      StopoverResponse,
      StopoverByOperationParamsType | void
    >({
      query: (params: StopoverByOperationParamsType) => {
        return {
          url: `operational/operations_resume`,
          method: 'GET',
          headers: getHeaders,
          params,
        };
      },
      transformResponse: (response: StopoverResponse) => {
        response.results.forEach((stopover) => {
          stopover.key = stopover.id;
          stopover.operation_id = stopover.id;
          // stopover.operation = {
          //   ...stopover.operation,
          //   inspection: String(stopover.operation.inspection),
          // };
        });
        return response;
      },
      providesTags: () => [{ type: 'Stopover', code: '3' }],
    }),
    getStopoveresGroupbyDues: build.query<
      StopoverResponse,
      GenericQueryParamsType
    >({
      query: (params) => {
        return {
          url: `${BASE_URL}/groupby-dues`,
          method: 'GET',
          params,
        };
      },
    }),
    getStopoverGroupbyDue: build.query<Stopover, number | undefined>({
      query: (stopoverId) => {
        return {
          url: `${BASE_URL}/groupby-dues/${stopoverId}`,
          method: 'GET',
        };
      },
    }),
    getStopoverGroupbyOperationById: build.query<Stopover, number>({
      query: (id) => {
        return {
          url: `${BASE_URL}/groupby-operations/${id}`,
          method: 'GET',
          headers: getHeaders,
        };
      },
      providesTags: (_results, _error, id) => [{ type: 'Stopover', code: id }],
      // providesTags: () => [{ type: 'Stopover', code: '5' }],
      transformResponse: (stopover: Stopover) => {
        stopover.key = stopover.id;
        stopover.id = stopover.operation?.id;
        stopover.operation = {
          ...stopover.operation,
          inspection: String(stopover.operation.inspection),
        };
        return stopover;
      },
    }),
    getStopoversResume: build.query<
      StopoverResponse,
      | (GenericQueryParamsType & {
          status?: string;
          order_by_delay_property?: string;
          shipowner_trip?: string;
        })
      | void
    >({
      query: (
        params: GenericQueryParamsType & {
          status?: string;
          order_by_delay_property?: string;
          shipowner_trip?: string;
        }
      ) => {
        return {
          url: `${BASE_URL}/resume`,
          method: 'GET',
          headers: getHeaders,
          params,
        };
      },
      transformResponse: (response: StopoverResponse) => {
        response.results.forEach((stopover) => {
          stopover.key = stopover.code;
          stopover.duv =
            stopover.duv?.length === 10
              ? maskString(stopover.duv || '', '######/####')
              : stopover.duv;
        });
        return response;
      },
      providesTags: () => [{ type: 'Stopover', code: '1' }],
    }),
    getStopoversGroupbyOperator: build.query<
      StopoversGroupByOperatorResponse,
      StopoverByOperationParamsType | void
    >({
      query: (params: StopoverByOperationParamsType) => {
        return {
          url: `${BASE_URL}/groupby-operators`,
          method: 'GET',
          headers: getHeaders,
          params,
        };
      },
      transformResponse: (response: StopoversGroupByOperatorResponse) => {
        response.results.forEach((stopover, index) => {
          stopover.key = index;
          stopover.id = stopover.operation_id;
        });
        return response;
      },
      providesTags: () => [{ type: 'Stopover', code: '3' }],
    }),
    getStopoverGroupbyOperator: build.query<
      StopoverGroupByOperatorType,
      StopoverByOperatorParamsType
    >({
      query: (params: StopoverByOperatorParamsType) => {
        return {
          url: `${BASE_URL}/groupby-operators`,
          method: 'GET',
          headers: getHeaders,
          params,
        };
      },
      transformResponse: (stopover: StopoverGroupByOperatorType) => {
        stopover.key = stopover.id;
        stopover.id = stopover.operation?.id;
        if (stopover.operation) {
          stopover.operation = {
            ...stopover.operation,
            inspection: String(stopover.operation?.inspection),
            items: stopover.operation.items?.filter(
              (item) => item.operator?.name === stopover.operator
            ),
          };
        }
        return stopover;
      },
    }),
    uploadManifest: build.mutation<
      void,
      { files: File[]; direction: string; id: number }
    >({
      query: (data) => {
        const { id } = data;
        const formData = new FormData();
        formData.append('file[]', data.files[0]);
        formData.append('file[]', data.files[1]);
        formData.append('direction', data.direction);
        return {
          url: `${BASE_URL}/${id}/cargo-manifest/upload`,
          method: 'POST',
          body: formData,
        };
      },
      invalidatesTags: (_result, error) =>
        getInvalidatesTags(error, [
          { type: 'Stopover', code: '4' },
          { type: 'NcmItem', code: '1' },
        ]),
    }),
    patchManifest: build.mutation<
      CargoManifestType,
      { id: number; cargo_navigation_type: number }
    >({
      query: (body) => {
        const { id } = body;
        return {
          url: `${BASE_URL}/cargo-manifest/${id}`,
          method: 'PATCH',
          body,
        };
      },
      invalidatesTags: (_result, error) =>
        getInvalidatesTags(error, [{ type: 'NcmItem', code: '1' }]),
    }),
    getUploadManifests: build.query<
      CargoManifestType[],
      { id: number; with_ncm_items: boolean }
    >({
      query: ({ id, with_ncm_items }) => {
        return {
          url: `${BASE_URL}/${id}/cargo-manifest/upload`,
          method: 'GET',
          params: { with_ncm_items },
        };
      },
      providesTags: () => [{ type: 'Stopover', code: '4' }],
      transformResponse: (response: CargoManifestType[]) => {
        return response.map((cargoManifest) => {
          cargoManifest.ce_merchants_groupby_operator_company =
            groupCEMerchantByOperatorCompany(
              cargoManifest.ce_merchant,
              cargoManifest.operation_direction
            );
          return cargoManifest;
        });
      },
    }),
    getStopoveresGroupbyDockingStatistics: build.query<
      StopoverGroupbyDockingStatisticsResponse,
      StopoverGroupbyDockingStatisticsParamsType
    >({
      query: (params) => {
        return {
          url: `${BASE_URL}/groupby-docking-statistics`,
          method: 'GET',
          headers: getHeaders,
          params,
        };
      },
      providesTags: () => [{ type: 'Stopover', code: '5' }],
    }),
    getStorageGroupbyOperations: build.query<
      StopoverResponse,
      {
        page?: number;
        cargo_status?: string;
      }
    >({
      query: (params) => ({
        url: '/operational/storages-merchant-group-by-operation',
        method: 'GET',
        params,
      }),
      providesTags: () => [{ type: 'Stopover', code: '6' }],
    }),
    getStorageGroupbyOperationById: build.query<Stopover, number>({
      query: (operationId) => {
        return {
          url: `/operational/storages-merchant-group-by-operation/${operationId}`,
          method: 'GET',
        };
      },
      providesTags: () => [{ type: 'Stopover', code: '7' }],
    }),
    getStopoveresGroupyStorageMerchant: build.query<
      StopoverGroupbyStorageMerchantType,
      number
    >({
      query: (stopoverId) => {
        return {
          url: `/maritime/stopoveres/groupby-storage-merchant/${stopoverId}`,
          method: 'GET',
        };
      },
      providesTags: () => [{ type: 'Stopover', code: '8' }],
    }),
    addStopoverPspManifests: build.mutation<void, { id: number; duv: string }>({
      query: (params) => {
        const { id, duv } = params;
        return {
          url: `${BASE_URL}/${id}/psp/${duv}/manifests`,
          method: 'POST',
        };
      },
      invalidatesTags: (_result, error) =>
        getInvalidatesTags(error, [
          { type: 'Stopover', code: '4' },
          { type: 'NcmItem', code: '1' },
        ]),
    }),
    patchAllOperatorManifestItems: build.mutation<
      void,
      {
        id: number;
        manifest_code: string;
        cargo_type: string;
        storage_terminal: number;
        operator: number;
      }
    >({
      query: (params) => {
        const { id, manifest_code, cargo_type, storage_terminal, operator } =
          params;
        return {
          url: `${BASE_URL}/${id}/cargo-manifest/${manifest_code}`,
          method: 'PATCH',
          body: { cargo_type, storage_terminal, operator },
        };
      },
      invalidatesTags: (_result, error) =>
        getInvalidatesTags(error, [{ type: 'NcmItem', code: '1' }]),
    }),
    exportWebsite: build.query<string, ReportsQueryParamsType>({
      query: (params) => {
        return {
          url: `${BASE_URL}/export-website`,
          method: 'GET',
          params,
          responseHandler: async (response) =>
            window.location.assign(
              window.URL.createObjectURL(await response.blob())
            ),
          cache: 'no-cache',
        };
      },
    }),
    exportBI: build.query<string, ReportsQueryParamsType>({
      query: (params) => {
        return {
          url: `${BASE_URL}/export-bi`,
          method: 'GET',
          params,
          cache: 'no-cache',
        };
      },
    }),
    exportPortDockingCargoReport: build.query<string, ReportsQueryParamsType>({
      query: (params) => {
        return {
          url: `${BASE_URL}/export-port-docking-cargo-report`,
          method: 'GET',
          params,
          cache: 'no-cache',
        };
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useAddStopoverMutation,
  useGetStopoverQuery,
  useGetStopoveresQuery,
  useUpdateStopoverMutation,
  useGetStopoverDockingChecklistQuery,
  useGetOperatorsQuery,
  useCancelMutation,
  useReactivateMutation,
  useDeleteProductMutation,
  useFilterStopoveresByNameOrCodeQuery,
  useLazyGetStopoverQuery,
  usePatchStopoverMutation,
  useGetStopoversGroupbyOperationQuery,
  useLazyGetStopoverGroupbyOperationByIdQuery,
  useGetStopoversResumeQuery,
  useLazyGetStopoversResumeQuery,
  useGetStopoversGroupbyOperatorQuery,
  useGetStopoverGroupbyOperatorQuery,
  useUploadManifestMutation,
  useGetUploadManifestsQuery,
  useGetStopoveresGroupbyDockingStatisticsQuery,
  usePatchManifestMutation,
  useGetStopoveresGroupbyDuesQuery,
  useGetStopoverGroupbyDueQuery,
  useGetStorageGroupbyOperationsQuery,
  useLazyGetStorageGroupbyOperationByIdQuery,
  useGetStopoveresGroupyStorageMerchantQuery,
  useAddStopoverPspManifestsMutation,
  usePatchAllOperatorManifestItemsMutation,
  useLazyExportWebsiteQuery,
  useLazyExportBIQuery,
  useLazyExportPortDockingCargoReportQuery,
} = stopoverApi;
