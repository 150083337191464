import {
  CheckCircleFilled,
  CloseCircleFilled,
  EditFilled,
  LoadingOutlined,
  MinusCircleFilled,
} from '@ant-design/icons';
import { Col, Row, Skeleton } from 'antd';
import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';

import { Button, CheckList, Collapse, Input } from '../..';
import { useGetConsentsQuery } from '../../../services/pspApi';
import {
  useGetStopoverDockingChecklistQuery,
  usePatchStopoverMutation,
} from '../../../services/stopoverApi';
import {
  ConsentRequirementDescriptionType,
  FinancialTableType,
  PhaseConsentDescriptionType,
  SituationConsentType,
  Stopover,
} from '../../../types';
import {
  isNullOrUndefined,
  removeSpecialCharacters,
} from '../../../utils/utils';
import { CheckListStyle } from '../CheckList';
import { CheckListItem } from '../CheckListItem';
import { EmptyDataPlaceholder } from '../emptyDataPlaceholder/EmptyDataPlaceholder';
import { panelHeaderTitle } from '../utils';

const { Panel } = Collapse;

type DocumentsConsentsDescriptionProps = {
  activeKey: string | string[];
  onOpenPanel: (panel: string | string[]) => void;
  selectedStopover: Stopover;
};

const { TextArea } = Input;

export function DocumentsConsentsDescription(
  props: DocumentsConsentsDescriptionProps
) {
  const { activeKey, onOpenPanel, selectedStopover } = props;

  const [Data, setData] = useState<
    {
      label: string;
      checked?: boolean;
      subItens?: { title: string; checked: boolean }[];
    }[]
  >([]);

  const { data: consents, isLoading: isLoadingConsents } = useGetConsentsQuery(
    removeSpecialCharacters(selectedStopover.duv || '0') || '0'
  );

  const { data: checklist, isLoading } = useGetStopoverDockingChecklistQuery(
    selectedStopover?.id || null
  );

  const [observations, setObservations] = useState(
    selectedStopover.observations || ''
  );
  const [isEditObs, setIsEditObs] = useState(false);

  useEffect(() => {
    setObservations(selectedStopover.observations || '');
  }, [selectedStopover.observations]);

  function createPanelHeaderTitle(
    item: PhaseConsentDescriptionType,
    checked: boolean,
    title: string
  ) {
    return (
      <CheckListItem
        checked={checked}
        title={title}
        tooltipText={item.situation_consent}
        checkedIcon={getCheckedIcon(item.situation_consent)}
      />
    );
  }

  function getCheckedIcon(isChecked: SituationConsentType) {
    return isChecked === 'Fornecida' ? (
      <CheckCircleFilled
        style={{ color: 'var(--success-dark)', marginRight: '10px' }}
      />
    ) : isChecked === 'Com exigências' ? (
      <CloseCircleFilled
        style={{ color: 'var(--error-medium)', marginRight: '10px' }}
      />
    ) : isChecked === 'Pré-fornecida' || isChecked === 'Em análise' ? (
      <MinusCircleFilled
        style={{ color: 'var(--warning-medium)', marginRight: '10px' }}
      />
    ) : null;
  }

  function getCurrentDockingFinancialTables() {
    const currentDocking = selectedStopover.dockings?.find(
      (docking) => docking.id === selectedStopover.current_docking_id
    );

    return isEmpty(currentDocking?.financial_tables) ||
      !currentDocking?.financial_tables
      ? undefined
      : currentDocking?.financial_tables.map((financialTable) => {
          return {
            title: financialTable.company.name,
            name: `financial-table-${financialTable.id}`,
            checked: financialTable.company_confirmed,
          };
        });
  }

  useEffect(() => {
    setData([
      {
        label: 'Calado de entrada',
        checked: checklist?.input_draught || false,
      },
      {
        label: 'Confirmação de armazenamento',
        checked: checklist?.storage_confirmed || false,
      },
      {
        label: 'DUV - Documento Único Virtual',
        checked: checklist?.duv || false,
      },
      {
        label: 'Comprimento (LOA)',
        checked: checklist?.length || false,
      },
      {
        label: 'DWT de entrada',
        checked: checklist?.input_dwt || false,
      },
      {
        label: 'Tipo de cabo',
        checked: checklist?.dock_line_type || false,
      },
      {
        label: 'Deslocamento para entrada',
        checked: checklist?.entrance_displacement || false,
      },
      {
        label: 'Confirmação dos responsáveis financeiros',
        checked: checklist?.financial_tables_confirmed || false,
        subItens: getCurrentDockingFinancialTables(),
      },
      {
        label: 'Manifesto de carga',
        checked: checklist?.cargo_manifest_data || false,
      },
    ]);
  }, [checklist]);

  const [patchStopover, { isLoading: isLoadingPatchStopover }] =
    usePatchStopoverMutation();
  async function handleSubmitObs() {
    await patchStopover({ id: selectedStopover.id, observations });
    setIsEditObs(false);
  }

  return (
    <Collapse
      activeKey={activeKey}
      onChange={onOpenPanel}
      expandIconPosition="end"
    >
      <Panel
        forceRender
        header="Documentações e anuências"
        key="documentsConsents"
        extra={panelHeaderTitle(selectedStopover.updated_at)}
      >
        <Row style={{ marginTop: '10px' }}>
          <Col span={11}>
            <Skeleton loading={isLoadingConsents || isLoading}>
              {consents && consents.length > 0 ? (
                <CheckListStyle>
                  <div style={{ marginBottom: '10px' }}>Documentações:</div>
                  <Collapse ghost expandIconPosition="end">
                    {consents[
                      consents?.findIndex(
                        (consent) => consent.step === 'Atracação'
                      )
                    ]?.consents?.map((consent, index) => {
                      if (consent.requirements.length > 0) {
                        return (
                          <Panel
                            header={createPanelHeaderTitle(
                              consent,
                              consent.checked_consent === 'ALL',
                              consent.consenting_sector
                            )}
                            key={`item-${index}`}
                            style={{ padding: '0px' }}
                          >
                            <ul
                              style={{
                                listStyle: 'none',
                                paddingLeft: '0px',
                              }}
                            >
                              {consent.requirements.map(
                                (
                                  subitem: ConsentRequirementDescriptionType
                                ) => {
                                  return (
                                    <CheckListItem
                                      key={subitem.name_requirement}
                                      checked={subitem.checked}
                                      title={subitem.name_requirement}
                                      tooltipText={
                                        subitem.requirement_situation
                                      }
                                    />
                                  );
                                }
                              )}
                            </ul>
                          </Panel>
                        );
                      }
                      return (
                        <CheckListItem
                          key={`item-${index}`}
                          checked={consent.checked_consent === 'ALL'}
                          title={consent.consenting_sector}
                          tooltipText={consent.situation_consent}
                          checkedIcon={getCheckedIcon(
                            consent.situation_consent
                          )}
                        />
                      );
                    })}
                  </Collapse>
                </CheckListStyle>
              ) : (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                  }}
                >
                  <div style={{ marginBottom: '10px' }}>Anuências:</div>
                  {isNullOrUndefined(selectedStopover.duv) ? (
                    <EmptyDataPlaceholder text="Preencha a DUV para exibir o status das anuências" />
                  ) : (
                    <EmptyDataPlaceholder
                      text={
                        <>
                          Não há dados no PSP para a DUV informada:{' '}
                          <span
                            style={{
                              fontFamily: 'monospace',
                              fontWeight: 'bold',
                            }}
                          >
                            {selectedStopover.duv}
                          </span>
                        </>
                      }
                    />
                  )}
                </div>
              )}
            </Skeleton>
          </Col>
          <Col span={2} />
          <Col span={11}>
            <CheckList
              title="Dados:"
              items={Data}
              labelField="label"
              isCollapsable
            />
          </Col>
        </Row>
        <div
          style={{
            display: 'flex',
            gap: '5px',
            marginBlockStart: '21px',
          }}
        >
          <span
            style={{
              color: 'var(--neutral_medium',
              fontSize: '12px',
            }}
          >
            Observações:
          </span>
          <TextArea
            value={observations}
            onChange={(e) => setObservations(e.target.value)}
            disabled={!isEditObs || isLoadingPatchStopover}
            onPressEnter={handleSubmitObs}
            maxLength={2000}
            bordered={false}
          />
          <Button
            icon={
              isLoadingPatchStopover ? (
                <LoadingOutlined />
              ) : isEditObs ? (
                <CheckCircleFilled />
              ) : (
                <EditFilled />
              )
            }
            onClick={() =>
              isEditObs
                ? handleSubmitObs()
                : setIsEditObs((editObs) => !editObs)
            }
            type="link"
            style={{ marginTop: '-8px' }}
          />
        </div>
      </Panel>
    </Collapse>
  );
}
